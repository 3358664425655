import { html, css, LitElement } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map.js';

import { dropdown } from './styles';
import { renderBackdrop } from '../../app-render/elements/backdrop/index.js';


 export class AppDropdown extends LitElement {

  static get styles() {
    return []
  }

  static get properties() {
    return {
      active: { type: Boolean },
      position: {type : Object },
      data: { type: Object },
    //  actions: { type: Object },
    };
  }


  constructor() {
    super();
    this.data={};
    this.position={};
    this.active=false;
  }






  render() {
    const { data, position, action, active } = this;


    return html`
    <style>
    .container,
    .list-view {
        border-radius: 2px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, .6)
    }

    * {
        box-sizing: border-box
    }

    .container {
        position: relative;
        top: -20px;
        height: auto;
        padding: 0;
        z-index: 200;
        -webkit-transition: transform .3s cubic-bezier(.465, .183, .153, .946), visibility .2s, opacity .2s linear;
        transition: transform .3s cubic-bezier(.465, .183, .153, .946), visibility .2s, opacity .2s linear;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        display:inline-flex;
    }

    .container[active] {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
        visibility: hidden;
        pointer-events: none
    }

    .list-view {
        position: absolute;
        overflow: auto;
        background: #FFF;
        max-height: 228px;
        cursor: pointer
    }


    .flex-none { flex: none;}.flex{display:flex}.inline-flex{display:inline-flex}.flex-column{flex-direction:column}.flex-row{flex-direction:row}.flex-wrap{flex-wrap:wrap}.flex-nowrap{flex-wrap:nowrap}.flex-wrap-reverse{flex-wrap:wrap-reverse}.flex-column-reverse{flex-direction:column-reverse}.flex-row-reverse{flex-direction:row-reverse}.items-start{align-items:flex-start}.items-end{align-items:flex-end}.items-center{align-items:center}.items-baseline{align-items:baseline}.items-stretch{align-items:stretch}.self-start{align-self:flex-start}.self-end{align-self:flex-end}.self-center{align-self:center}.self-baseline{align-self:baseline}.self-stretch{align-self:stretch}.justify-start{justify-content:flex-start}.justify-end{justify-content:flex-end}.justify-center{justify-content:center}.justify-between{justify-content:space-between}.justify-around{justify-content:space-around}.order-0{order:0}.order-1{order:1}.order-2{order:2}.order-3{order:3}.order-4{order:4}.order-5{order:5}.order-6{order:6}.order-7{order:7}.order-8{order:8}.order-last{order:99999}.flex-grow1{flex-grow:1}.flex-grow2{flex-grow:2}.flex-grow3{flex-grow:3}.flex-grow4{flex-grow:4}.flex .flex{height:100%}.flex .flex *{height:auto;width:100%;flex-wrap:wrap;margin:0;display:inherit;-webkit-align-items:center;-ms-flex-align:center;align-items:center;}.w-100{width:100%}.h-25{height:25%}.h-50{height:50%}.h-75{height:75%}.h-100{height:100%}.min-h-100{min-height:100%}.vh-25{height:25vh}.vh-50{height:50vh}.vh-75{height:75vh}.vh-100{height:100vh}


/*horizontal menu*/
/*#menu-items {white-space: nowrap;}*/

    </style>


    <slot @click="${e => this._toggleActiveState(e)}" name="trigger" id="trigger"></slot>
    <div
    ?active=${!this.active}
    class="container"
    >
    <div  class="list-view" ?active=${!this.active} @click="${e => this._handleSelectedItem(e)}">
    <slot name="slot" id="menu-items" class=""></slot>
      </div>
      </div>
      ${renderBackdrop(active, true)}
    `;
  }

  firstUpdated(){
    const currentTargetOffset= this.shadowRoot.querySelector('.container').getBoundingClientRect();
    const targetOffset=this.shadowRoot.querySelector('.list-view').getBoundingClientRect();
    const dif=targetOffset.right-currentTargetOffset.right
    const off=targetOffset.width*-1+'px';
    this.shadowRoot.querySelector('.list-view').style.right='0px';
    //this.shadowRoot.querySelector('.list-view').style.top=(this.offsetHeight/2)+'px';



  }

  updated(changedProperties) {
    super.updated(changedProperties);



    if (changedProperties.has('active') && this.active===true) {

      /*const offset= this.getBoundingClientRect();
      const offset2= this.shadowRoot.querySelector('.container').getBoundingClientRect();
console.log(this.clientHeight)


    this.shadowRoot.querySelector('.content').style.left = offset.left+'px';
    this.shadowRoot.querySelector('.content').style.top = (offset2.top/2)+'px';
*/
    }
  }







  _handleSelectedItem (e) {
  //  console.log(e.target);
    //  console.log(e.target.getAttribute('value'));
      //this.dispatchEvent(new CustomEvent('item-selected', {detail: {value : e.target.getAttribute('value') }}))

  }

  _changeHandler (e) {
    this.active=!this.active
    this.dispatchEvent(new CustomEvent('active-updated'))
}


_toggleActiveState (e, xPos) {
  //Switch menu xPos if needed...
const target= e.target.getBoundingClientRect();
const targetOffset=this.shadowRoot.querySelector('.list-view').getBoundingClientRect();
if(target.x-targetOffset.width<0){
  xPos=-(e.clientX-target.width/2)
} else {
  xPos=(target.right-e.clientX/*-target.width/2*/);
}
this.shadowRoot.querySelector('.list-view').style.right=xPos+'px';

this.active=!this.active;

}


__renderDropdownStyles() {
  const styles={}
//  styles.fixed= this.data.fixed;
//  styles[this.data.locationX]=this.data.locationX;
//  styles[this.data.locationY]=this.data.locationY;
  styles.fadeIn=this.active;
  styles.fadeOut=!this.active;
  return classMap(styles);

}

}
